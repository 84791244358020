import { HTTP } from '../../axios/axios'

async function getAll () {
  try {
    const res = await HTTP.get('/roles')
    return res.data
  } catch (error) {
    console.log(error)
  }
}
async function getPermissions () {
  try {
    const res = await HTTP.get('/permission')
    return res.data
  } catch (error) {
    console.log(error)
  }
}
async function changePermissions (permissions, id) {
  return await HTTP.patch('/roles/' + id, permissions)
}

export default { getAll, getPermissions, changePermissions }
