<template>
  <div class="roles relative flex item-center justify-center">
    <CheckTable
      v-if="!isFetching"
      :tableType="'Roles'"
      :columnNames="columnNames"
      @getData="getData()"
      @changeData="changeData"
    />
  </div>
</template>

<script>
import CheckTable from '@/components/CheckTable.vue'
import requests from '@/services/requests/RolesRequests.js'

export default {
  data () {
    return {
      isFetching: true,
      columnNames: []
    }
  },
  name: 'Roles',
  components: {
    CheckTable
  },
  methods: {
    async getData () {
      this.$emit('loading', true)
      let roles = await requests.getAll()
      roles = Array.from(roles, (role) => {
        return {
          name: role.name,
          _id: role._id,
          permission: Array.from(
            this.columnNames,
            (i) =>
              Array.from(role.permission, (j) => j.name).indexOf(
                i.name.toLowerCase()
              ) !== -1
          )
        }
      })
      this.$store.commit('setData', roles)
      this.$emit('loading', false)
    },
    changeData (data, n) {
      const payload = {}
      payload.name = data[n].name
      payload.permissionId = []
      for (let i = 0; i < data[n].permission.length; i++) {
        if (data[n].permission[i]) {
          payload.permissionId.push(this.columnNames[i]._id)
        }
      }
      requests.changePermissions(payload, data[n]._id)
    }
  },
  async created () {
    this.columnNames = await requests.getPermissions()
    this.getData().finally(() => (this.isFetching = false))
  }
}
</script>
